import React from "react"

import styles from "./../../../policies-css-module.module.scss"
import "../../../../styles.scss"

import { Layout } from '../../../../components'

import JulyOffer2Tnc from "../../../../components/policies/julyOffer2/july-offer-2-tnc"
import { Link } from "gatsby"

const JulyOffer = () => {
  return (
    <>
      <Layout>
        <div className={styles.container}>
          <div className={styles.link_section}>
            <div className={styles.link_section_div}>
              <Link
                exact
                className={styles.tc_links}
                activeClassName="is-active"
                to="/promos/julyoffer/tnc/"
              >
                JULY OFFER 
              </Link>
            </div>
          </div>
          <div className={styles.content_section}>
            <JulyOffer2Tnc />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default JulyOffer
